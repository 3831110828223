import React from "react";
import { Link } from "react-router-dom";
import { SiGmail } from "react-icons/si";
import { FaWhatsapp, FaFacebook, FaTwitter, FaYoutube, FaLinkedin, FaInstagram } from "react-icons/fa";
import P1 from "../assets/Image/googl_partner.webp"
import P2 from "../assets/Image/google_cloud.webp"
import P3 from "../assets/Image/MCaAA.webp"
import P4 from "../assets/Image/aws-partner-logo.webp"
import P5 from "../assets/Image/bing-partner-logo.webp"
import P6 from "../assets/Image/facebook_partner.webp"
import Logo from "../assets/Image/GHOSTING.png"

function Footer() {
  return (
    <div>
      <div className="footer section position-relative">
        <div className="container">
          <hr className="bottomhr" />
          <div className="orng-bg"></div>
          <div className="footer-wraper">
            <div className="row">
              <div className="col-sm-12 col-md">
                <ul className="bottom-footer">
                  <li>
                    <Link to="/">
                      <img className="img-fluid" src={Logo} width="100" alt="ghosting logo" loading="lazy" />
                    </Link>
                  </li>
                  <li>
                    Ghosting Tech - 360° Digital Marketing Agency, A community of like-minded people united by a common purpose of helping our clients build the content strategy that engages, converts and converts your leads to paying customers.
                  </li>
                  <br />
                  <li>
                    <button className=" btn btn-warning">
                      <a href="https://wa.me/9470017395" className="text-white">Book FREE Consultation</a>
                    </button>
                  </li>
                  <div className="copy-r ml-0 p-0">
                    <ul className="p-0">
                      <li>
                        <Link to="https://wa.me/919470017395" target="_blank" rel="noopener noreferrer" className="social-a" title="Whatsapp">
                          <FaWhatsapp style={{ color: '#25D366' }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.facebook.com/ghostingtech" target="_blank" rel="noopener noreferrer" className="social-a" title="Facebook">
                          <FaFacebook style={{ color: '#1877F2' }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="https://x.com/ghostingwebtech" target="_blank" rel="noopener noreferrer" className="social-a" title="Twitter">
                          <FaTwitter />
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.youtube.com/@ghostingwebtech" target="_blank" rel="noopener noreferrer" className="social-a" title="Youtube">
                          <FaYoutube style={{ color: 'red' }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.linkedin.com/company/ghostingwebtech" target="_blank" rel="noopener noreferrer" className="social-a" title="Linkedin">
                          <FaLinkedin style={{ color: '#0072b1' }} />
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.instagram.com/ghostingwebtech" target="_blank" rel="noopener noreferrer" className="social-a" title="Instagram">
                          <FaInstagram style={{ color: '#d62976' }} />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </ul>
              </div>
              <div className="col-sm-12 col-md ">
                <h5 className="realistic-marker-highlight  fs-3">Our Specialization</h5>
                <ul className="fotter-ul">
                  <li><a href="https://ghosting.in/fullsetuppage.html" rel="noopener noreferrer">Full Digital Appearance Setup</a></li>
                  <li><a href="https://ghosting.in/local-seo-services.html">Local SEO Services</a></li>
                  <li><a href="https://ghosting.in/custom-design.html">Custom Design</a></li>
                  <li><a href="https://ghosting.in/wordpress-design.html">Wordpress Design</a></li>
                  <li><a href="https://ghosting.in/web-dev.html">Web App Development</a></li>
                  <li><a href="https://ghosting.in/mobile-dev.html">Mobile App Development</a></li>
                  <li><a href="/https://ghosting.in/social-media-agency-india.html">Social Media Marketing</a></li>
                  <li><a href="https://ghosting.in/paid-marketing-agency-india.html">Paid Marketing</a></li>
                  <li><a href="https://ghosting.in/custom-seo-soln.html">Custom Seo Solution</a></li>
                  <li><a href="/local-seo-services.html">Local Seo GMB</a></li>
                  <li><a href="/linkedin-marketing.html">Linkedin Marketing</a></li>
                </ul>
              </div>
              <div className="col-sm-12 col-md">
                <div className="">
                  <h5 className="realistic-marker-highlight fs-3 ">We Serve</h5>
                </div>

                <ul className="fotter-ul">
                  <li><Link to="#">Hospital And Doctors</Link></li>
                  <li><Link to="#">Event Planner</Link></li>
                  <li><Link to="#">Restaurants</Link></li>
                  <li><Link to="#">Finance</Link></li>
                  <li><Link to="#">Education</Link></li>
                  <li><Link to="#">E-commerce</Link></li>
                  <li><Link to="#">Small Business</Link></li>
                  <li><Link to="#">Real Estate</Link></li>
                  <li><Link to="#">Rehab center</Link></li>
                </ul>
              </div>
              <div className="col-sm-12 col-md">
                <h5 className="realistic-marker-highlight  fs-3">Our Company</h5>
                <ul className="fotter-ul">
                  <li><a href="https://ghosting.in/about-us.html">About Us</a></li>
                  <li><a href="https://ghosting.in/contact-us.html">Contact Us</a></li>
                  <li><a href="https://ghosting.in/blog.html">Latest Blogs</a></li>
                  <li><Link to="#">Internship</Link></li>
                  <li><a href="https://ghosting.in/terms.html">T&C</a></li>
                  <li><a href="https://ghosting.in/privacy_policy.html">Privacy policy</a></li>
                  <li><a href="https://ghosting.in/refund_policy.html">Return Policy</a></li>
                </ul>
                <div className="resel">
                  <Link to="mailto:hr@ghosting.in" target="_blank" className="sel-btn" ghosting-no="113">
                    <span><SiGmail style={{ color: '#e7e40d' }} /></span>

                    <div>hr@ghosting.in</div>
                  </Link>
                </div>
                <div className="resel resell">
                  <Link to="mailto:contact@ghosting.in" target="_blank" className="sel-btn sell-btn" ghosting-no="113">
                    <span><SiGmail style={{ color: '#0dcee7' }} /></span>
                    <div>contact@ghosting.in</div>
                  </Link>
                </div>
              </div>
              <hr className="bottomhr" />
            </div>
          </div>
          <div className="sectionfooter-2">
            <div className="container-fluid logo-footer">
              <div className="row ">
                <div className="col-sm-2 col-md-2 col-6 mb-4">
                  <Link to="#"><img className="img-fluid shadow rounded h-100 w-75" src={P1} alt="Google Partner" loading="lazy" /></Link>
                </div>
                <div className="col-sm-2 col-md-2 col-6 mb-4">
                  <Link to="#"><img className="img-fluid shadow rounded h-100 w-75" src={P2} alt="Facebook-Partner" loading="lazy" /></Link>
                </div>
                <div className="col-sm-2 col-md-2 col-6 mb-4">
                  <Link to="#">
                    <img className="img-fluid shadow rounded h-100 w-75" src={P3} alt="AWS Cloud logo" loading="lazy" />
                  </Link>
                </div>
                <div className="col-sm-2 col-md-2 col-6 mb-4">
                  <Link to="#"><img className="img-fluid shadow rounded h-100 w-75" src={P4} alt="Bing Partner" loading="lazy" /></Link>
                </div>
                <div className="col-sm-2 col-md-2 col-6 mb-4">
                  <Link to="#"><img className="img-fluid shadow rounded h-100 w-75" src={P5} alt="mca logo" loading="lazy" /></Link>
                </div>
                <div className="col-sm-2 col-md-2 col-6 mb-4">
                  <Link to="#"><img className="img-fluid shadow rounded h-100 w-75" src={P6} alt="Google Cloud logo" loading="lazy" /></Link>
                </div>
              </div>
            </div>
            <div className="container-fluid copyright-div p-0">
              <div className="row">
                <div className="col-sm-12">
                  <div className="copy-r text-center">
                    <span>GHOSTING WEBTECH PVT LTD. 2024 || All Right Reserved </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;